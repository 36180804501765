$(document).foundation();

$(document).ready(function(){

    $('.leftslider').slick({
        arrows: false,
        autoplay: true,
        fade: true,
        pauseOnHover: false
    });

    $('.menuicon').click(function(){
        $('nav').slideToggle();
        $('body').toggleClass('menuopen');
    });

    $('.homeSlider').slick({
        arrows: false,
        autoplay: true,
        fade: true,
        pauseOnHover: false
    });

});